import { n as normalizeComponent } from "./index.js";
import "lodash";
import "@kraftheinz/common";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-modal", { staticClass: "modal-edit-cooling", attrs: { "centered": true, "width": 1e3, "after-close": _vm.afterModalClose, "title": "View Edit Cooling" }, model: { value: _vm.visible, callback: function($$v) {
    _vm.visible = $$v;
  }, expression: "visible" } }, [_c("list-composer", { attrs: { "data-source": _vm.resourceList, "custom-margin-filter": "", "custom-padding-table": "", "custom-url-edit-in-row": _vm.customUrlEditInRow, "has-active": false, "has-edit-in-row": true, "hide-pagination": true } }, [_c("text-field", { key: "CookerNo", attrs: { "data-index": "cookerNo", "title": "Cooker ID" } }), _c("text-field", { key: "pcInletTemp", attrs: { "data-index": "pcInletTemp", "title": "P.C. Inlet Temp", "edit-in-row": "text-input" } }), _c("text-field", { key: "pcOutletTemp", attrs: { "data-index": "pcOutletTemp", "title": "P.C. Outlet Temp", "edit-in-row": "text-input" } }), _c("text-field", { key: "PSI", attrs: { "data-index": "psi", "title": "PSI", "edit-in-row": "text-input" } }), _c("text-field", { key: "ACInletTemp", attrs: { "data-index": "acInletTemp", "title": "A.C. Inlet Temp", "edit-in-row": "text-input" } }), _c("text-field", { key: "ACOutletTemp", attrs: { "data-index": "acOutletTemp", "title": "A.C. Outlet Temp", "edit-in-row": "text-input" } })], 1), _c("template", { slot: "footer" }, [_c("a-button", { key: "submit", attrs: { "type": "primary" }, on: { "click": _vm.onCloseButton } }, [_vm._v("Close")])], 1)], 2);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditCooling",
  inject: ["crud", "apiUrl"],
  data() {
    const steriId = this.$route.params.id;
    return {
      customUrlEditInRow: `${this.apiUrl}/sterilization/data-entries/${steriId}/cookers/RECORD_ID`,
      visible: false,
      redirectRoute: `/sterilization-specifications/data-entries/${steriId}`
    };
  },
  computed: {
    resourceList() {
      return this.crud.getList();
    }
  },
  async mounted() {
    this.visible = true;
  },
  methods: {
    afterModalClose() {
      this.$router.push(this.redirectRoute);
    },
    onCloseButton() {
      this.visible = false;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListEditCooling = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-cooling" }, [_c("resource", { attrs: { "name": "sterilization.data-entries.cookers", "api-url": _vm.apiUrl } }, [_c("list-edit-cooling")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListEditCooling
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
